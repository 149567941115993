import React, { FC, useEffect, useState } from 'react';
import { MainLayout } from '../../common/layouts';
import { PageHeader } from '../../common/components'
import { useViewConfig } from '../../common/hooks/use-view-config';
import { KpiSummary } from './widgets/kpi-summary.w';
import { KpiTrend } from './widgets/kpi-trend.w';
import { MultimetricTrendTable } from './widgets/multimetric-trend.table';
import { SegmentedReportTable } from './widgets/segmented-report.table';
import { ReportTypes } from '../../services/views';
import { AnalyticsTablecard } from './widgets/scorecard.table';
import { ViewBanner } from '../../common/components/view-banner';
import { NpsBreakdownDrilldownTable } from './widgets/nps-breakdown-drilldown.table'
import { KpiCount } from './widgets/kpi-count.w';
import { KpiCountTable } from './widgets/kpi-count-table.w';
import { CSATAttributesTable } from './widgets/csat-attributes.table';
import { SegmentedReportQuestionTable } from './widgets/breakdown-report-question.table';
import {Tabs, Tab, Box} from '@material-ui/core';
import { DataListTable } from './widgets/data-report';
import { SummaryReportTable } from './widgets/summary-imported-record.report';

function TabPanel(props:any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

function tabsProps(index:number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const GetReports = (props: any) => {
  const { viewConfig, tab } = props;

  return (
    <>
    {viewConfig.reports?.map((report: any) => {
      switch(report.type) {
        case ReportTypes.KPI_SUMMARY:
          if (tab){
            if (tab.hideTypes?.includes(ReportTypes.KPI_SUMMARY)) return null
            if (tab.field) report.query.field = 'answerObj.data.' + tab.field
            report.options.kpiName = tab.name
            if (tab.baseFilters)  report.query.baseFilters = tab.baseFilters;
          }
          return <KpiSummary {...report}/>
        case ReportTypes.KPI_TREND:
          if (tab){
            if (tab.hideTypes?.includes(ReportTypes.KPI_TREND)) return null
            if (tab.field) report.query.field = 'answerObj.data.' + tab.field
            report.title.es= `Tendencia ${tab.name}`
            if (tab.baseFilters)  report.query.baseFilters = tab.baseFilters;
          }
          return <KpiTrend {...report} />
        case ReportTypes.KPI_COUNT:
          return <KpiCount {...report} />
        case ReportTypes.KPI_COUNT_TABLE:
          return <KpiCountTable {...report} />
        case ReportTypes.MULTIMETRIC_TREND:
          return <MultimetricTrendTable {...report} />
        case ReportTypes.MULTIVAR_BREAKDOWN:
          return <SegmentedReportTable {...report} />
        case ReportTypes.NPS_BREAKDOWN:
          return <NpsBreakdownDrilldownTable {...report} />
        case ReportTypes.PERFORMANCE:
          return <AnalyticsTablecard {...report} />
        case ReportTypes.CSAT:
          if (tab){
            if (tab.hideTypes?.includes(ReportTypes.CSAT)) return null
            if (tab.field) report.query.variable.name = tab.field
            report.title.es= tab.name
            if (tab.attributes) report.query.attributes = tab.attributes
            if (tab.baseFilters)  report.query.baseFilters = tab.baseFilters;
          }
          return <CSATAttributesTable {...report} />
        case ReportTypes.QUESTION_BREAKDOWN:
          return <SegmentedReportQuestionTable {...report} />
        case ReportTypes.DATA_LIST:
          return <DataListTable {...report} />
        case ReportTypes.SUMMARY_IMPORTED_RECORDS:
          return <SummaryReportTable {...report} />
        default:
          return <p>Invalid report type</p>
      }
    })}
   </>
  )
}

const AnalyticsScreen: FC = () => {
  const { loadingView, loadViewConfig, viewConfig } = useViewConfig();
  const [value, setValue] = useState(0);

  useEffect(() => {
    void loadViewConfig();
  }, [loadViewConfig]);

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      {loadingView ? (
        <div>Cargando vista...</div>
      ) : viewConfig ? (
        <>
          {viewConfig.infoMessage ? (
            <ViewBanner {...viewConfig.infoMessage} />
          ) : null}
          <PageHeader
            title={viewConfig.viewOptions.pageTitle}
            showFilters={viewConfig.viewOptions.hasOwnProperty('showFilters') ? viewConfig.viewOptions.showFilters : true}
            showRangeInput
            showMainFilters={viewConfig.viewOptions.showMainFilters}
            showRangeInputCompare={viewConfig.viewOptions.showCompareRangeInput}
          />
          {viewConfig.viewOptions.tabs ? 
            <>
              <Tabs value={value} onChange={handleChange} aria-label="reports" style={{marginBottom: '18px', backgroundColor: 'white'}} indicatorColor="primary">
                {viewConfig.viewOptions.tabs.map((tab: any, idx: number) => {
                  return <Tab label={tab.name} {...tabsProps(idx)} />
                })}
              </Tabs>
              { viewConfig.viewOptions.tabs?.map((tab: any, idx: number) => {
                  return <TabPanel value={value} index={idx}>
                          <div className={viewConfig.viewOptions.hasOwnProperty('showGrid') ? (viewConfig.viewOptions.showGrid ? "widget-grid" : "") : "widget-grid"}>
                            <GetReports viewConfig={viewConfig} tab={tab}/>
                          </div>
                        </TabPanel>
              })}
            </>
          : <div className={viewConfig.viewOptions.hasOwnProperty('showGrid') ? (viewConfig.viewOptions.showGrid ? "widget-grid" : "") : "widget-grid"}>
              <GetReports viewConfig={viewConfig}/>
            </div>
          }
        </>
      ) : (
        <div>Ocurrió un problema al cargar la vista...</div>
      )}
    </MainLayout>
  );
}

export {
  AnalyticsScreen,
};
