import { useEffect, useState } from 'react'
import BaseTable from '../../../common/widgets/table.w'
import { GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid'
import { SupportedLanguages } from '../../../services/filters'
import { ReportConfig } from '../../../services/views/views.models'
import useCSATAttributes, { CSATAttributesConfig } from '../../../common/hooks/use-csat-attributes'
import { Tooltip } from '@material-ui/core'

export interface ScaleFormatDef {
  max: number,
  className: string,
}

export interface CsatAttributesTableOptions {
  segmentTitle: string,
  resultToDisplay: 'n' | 'result',
  scaleFormatters: Record<string, ScaleFormatDef[]>,
}

export type CsatAttributesTableProps = ReportConfig<
  CSATAttributesConfig,
  CsatAttributesTableOptions
>

const makeGetDisplay = (field: string, language: SupportedLanguages): GridColDef['valueGetter'] => {
  return (params: GridValueGetterParams) => {
    return params.row[field]?.[language] ?? '-'
  }
}

const baseColProps: Partial<GridColDef> = {
  minWidth: 152,
  headerAlign: 'right',
  align: 'right',
  type: 'number',
  sortable: true
}

const CSATAttributesTable = ({
  title = { es: '', default: '' },
  query,
  options: {
    segmentTitle,
  },
}: CsatAttributesTableProps) => {
  const [loadReport, loading, error, csatReport] = useCSATAttributes(query);
  const [tableCols, setTableCols] = useState<GridColDef[]>([]);
  const [data, setData] = useState<Array<any>>([]);
  const preferredLang = SupportedLanguages.es
  const cols: GridColDef[] = [
    {
      field: 'display',
      type: 'string',
      headerName: segmentTitle,
      disableColumnMenu: true,
      flex: 1.25,
      valueGetter: makeGetDisplay('display', preferredLang),
      renderCell: ({ value }: GridCellParams) => (
        <Tooltip title={value || ''}>
          <span>{value}</span>
        </Tooltip>
      ),
      sortable: false
    },
    {
      ...baseColProps,
      field: 'negative',
      headerName: 'Negativo',
    },
    {
      ...baseColProps,
      field: 'negative_percentage',
      headerName: 'Negativo %',
    },
    {
      ...baseColProps,
      field: 'result',
      headerName: 'Positivo',
    },
    {
      ...baseColProps,
      field: 'result_percentage',
      headerName: 'Positivo %',
    },
    {
      ...baseColProps,
      field: 'n',
      headerName: 'Total',
    },
    {
      ...baseColProps,
      field: 'percentage',
      headerName: 'Total %',
    }
  ]

  useEffect(() => {
    loadReport();
  }, [loadReport]);

  useEffect(() => {
    if (!loading && !error && csatReport) {
      const chartData = csatReport.dataReport.slice().sort((p1, p2) => (p1.n < p2.n) ? 1 : (p1.n > p2.n) ? -1 : 0);
      setTableCols(cols);
      setData(chartData);
    }
  }, [loading, error, csatReport]);
  
  return (
    <div className="span-two-cols">
      <BaseTable
        rowIdKey="id"
        rows={data}
        columns={tableCols}
        loading={loading}
        onReload={loadReport}
        rowHeight={48}
        hideFooter
        autoHeight
        tableTitle={title[preferredLang]}
        downloadFileName={title[preferredLang]}
        localeText={{
          toolbarFilters: '',
          toolbarExport: '',
          toolbarColumns: 'Columnas',
          noRowsLabel: 'No se encontraron registros.',
          toolbarExportCSV: 'Descargar CSV',
          toolbarExportLabel: 'Exportar',
          toolbarFiltersTooltipShow: 'Mostrar filtros',
          toolbarFiltersTooltipHide: 'Ocultar filtros',
        }}
      />
    </div>
  )
}
export {
  CSATAttributesTable
}